import React, { Fragment, useEffect, useState } from "react";
import { Card, CardBody, Col, Table } from "reactstrap";
import { H5 } from "../../../AbstractElements";
import { Amount, Balance, DueDate, Invoice, InvoiceDate, Name, PaymentDues } from "../../../Constant";
import HeaderCard from "../../Common/Component/HeaderCard";
import { Link } from 'react-router-dom';
import { getDataWithToken } from "../../../Utils/restUtils";
import { getPaymentDue } from "../../../CommonURL";
import { toast } from "react-toastify";

const Payment = () => {
    const [paymentDueData, setPaymentDueData] = useState([]);

    useEffect(() => {
        recentPayData();
    }, []);

    const recentPayData = async () => {
        const data = await getDataWithToken(getPaymentDue);
        if (data.status) {
            setPaymentDueData(data.data);
        } else {
            toast.error(data.message)
        }
    };
    return (
        <Fragment>
            <Col xl="3" md="5 box-col-30">
                <Card className="transaction-history" style={{ height: '505px' }}>
                    <HeaderCard title={PaymentDues} setting={true} />
                    <CardBody>
                        <div className="table-responsive sroller-style" style={{ height: '440px', overflowY: 'auto' }}>
                            <Table className="table table-bordernone table-responsive">
                                <thead>
                                    <tr>
                                        <th>{Name}</th>
                                        <th>{Invoice}</th>
                                        <th>{Amount}</th>
                                        <th>{Balance}</th>
                                        <th>{DueDate}</th>
                                        <th>{InvoiceDate}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {paymentDueData.map((item, index) => {
                                        return (<tr key={index}>
                                            <td>
                                                <Link style={{ color: '#534686', marginLeft: '13px' }} to={`${process.env.PUBLIC_URL}/report/payment-collection?user=${item.agent_id}`}>
                                                    <H5 >{item.user_name}</H5>
                                                </Link>
                                            </td >
                                            <td>
                                                <Link to={`${process.env.PUBLIC_URL}/companies/company-list/userProfile/invoice?id=${item.user_id}&order_id=${item.order_id}`}>{item.invoice_number}</Link>
                                            </td>
                                            <td>
                                                <H5>{item.amount}</H5>
                                            </td>
                                            <td>
                                                <H5>{item.balance}</H5>
                                            </td>
                                            <td>
                                                <H5>{item.due_date}</H5>
                                            </td>
                                            <td>
                                                <H5>{item.invoice_date}</H5>
                                            </td>
                                        </tr>)
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Fragment>


    );
};

export default Payment;
