import React from 'react';
import { H4 } from '../../AbstractElements';
import { Link } from 'react-router-dom';
import InvoiceActionCell from '../../Components/Billing/invoice/ActionCell';
import CompanyActionCell from '../../Components/Companies/companies/CompanyActionCell';


export const TempDataColumns = [
  {
    name: <H4>Sl</H4>,
    selector: row => row.Si,
    sortable: true,
    width: '70px'
  },
  {
    name: <H4>Name</H4>,
    selector: row => row.Name,
    sortable: true,
    width: '170px',
    wrap: true,
  },
  {
    name: <H4>Email</H4>,
    selector: row => row.Email,
    sortable: true,
    width: '230px',
    wrap: true,
  },
  {
    name: <H4>Phone No.</H4>,
    selector: row => "+" + row.Mobile_Number,
    sortable: true,
    width: '170px',
    wrap: true,
  },
  {
    name: <H4>Industry</H4>,
    selector: row => row.Industry,
    sortable: true,
    width: '150px',
    wrap: true,
  },
  {
    name: <H4>State</H4>,
    selector: row => row.State,
    sortable: true,
    width: '150px',
    wrap: true,
  },
  {
    name: <H4>Country</H4>,
    selector: row => row.Country,
    sortable: true,
    width: '150px',
    wrap: true,
  },
];

export const PaymentColumns = [
  {
    name: <H4>Sl</H4>,
    selector: row => row.Si,
    sortable: true,
    width: '70px'
  },
  {
    name: <H4>Name</H4>,
    selector: row => row.Name,
    sortable: true,
    width: '150px',
    wrap: true,
  },
  {
    name: <H4>Invoice No</H4>,
    // selector: row => <Link to={`${process.env.PUBLIC_URL}/companies/company-list/userProfile/invoice?id=${row.User_id}&order_id=${row.Id}`}>{row.Invoice_no}</Link>,
    selector: row => row.Invoice_no,
    sortable: true,
    width: '120px',
    wrap: true,
  },
  {
    name: <H4>Txn Id</H4>,
    selector: row => row.Txn_id,
    sortable: true,
    width: '170px',
    wrap: true,
  },
  {
    name: <H4>Industry</H4>,
    selector: row => row.Industry,
    sortable: true,
    width: '120px',
    wrap: true,
  },
  {
    name: <H4>Payment Method</H4>,
    selector: row => row.Payment_method,
    sortable: true,
    width: '150px',
    wrap: true,
  },
  {
    name: <H4>Date</H4>,
    selector: row => row.Date,
    sortable: true,
    width: '150px',
    wrap: true,
  },
  {
    name: <H4>Amount</H4>,
    selector: row => row.Amount,
    sortable: true,
    width: '100px',
    wrap: true,
  },
  {
    name: <H4>Status</H4>,
    selector: row => row.Status,
    cell: row => (
      <span
          className={`badge badge-${row.Status}`}
      >
          {row.Status}
      </span>
  ),  

    sortable: true,
    width:'80px',
    wrap:true,
  },
];

export const InvoiceDataColumns = (viewForm) => [
  {
      name: <H4>Sl</H4>,
      selector: row => row.Si,
      sortable: true,
      width: '60px',
      wrap: true
  },
  {
      name: <i className="fa fa-gears" style={{ fontSize: '15px' }}></i>,
      selector: row => row.action,
      cell: row => <InvoiceActionCell row={row} viewForm={viewForm}/>,
      center: true,
      width: '55px',
  },
  {
      name: <H4>Payment Status</H4>,
      id: 'payment_status',
      selector: row => row.Payment_status,
      sortable: true,
      width: '90px',
      wrap: true,
      cell: row => (
          <span
              className={`badge badge-${row.Payment_status}`}
          >
              {row.Payment_status}
          </span>
      ),
  },
  {
      name: <H4>Invoice No</H4>,
      id: 'invoice_number',
      selector: row => <Link to={`${process.env.PUBLIC_URL}/companies/company-list/userProfile/invoice?id=${row.User_id}&order_id=${row.Id}`}>{row.Invoice_no}</Link>,
      sortable: true,
      width: '100px',
      wrap: true
  },
  {
      name: <H4>Invoiced Date</H4>,
      id: 'invoice_date',
      selector: row => row.Invoiced_at,
      sortable: true,
      width: '130px',
      wrap: true
  },
  {
      name: <H4>End Date</H4>,
      id: 'end_date',
      selector: row => row.End_at,
      sortable: true,
      width: '110px',
      wrap: true
  },
  {
      name: <H4>User Name</H4>,
      id: 'user_name',
      selector: row => row.User_name,
      sortable: true,
      width: '150px',
      wrap: true
  },
  {
      name: <H4>Company Name</H4>,
      id: 'company_name',
      selector: row => row.Company_name,
      sortable: true,
      width: '150px',
      wrap: true
  },
  {
      name: <H4>Amount</H4>,
      id: 'amount',
      selector: row => <span className=' fw-bolder billerq_color'>₹ {row.Amount}</span>,
      sortable: true,
      width: '90px',
      wrap: true,
  },
  {
      name: <H4>Balance</H4>,
      id: 'balance',
      selector: row => <span className=' fw-bolder billerq_color'>₹ {row.Amount}</span>,
      sortable: true,
      width: '110px',
      wrap: true
  },
  {
      name: <H4>Status</H4>,
      id: 'order_status',
      selector: row => row.Status,
      sortable: true,
      width: '90px',
      wrap: true,
      cell: row => (
          <span
              className={`badge badge-${row.Status}`}
          >
              {row.Status}
          </span>
      ),
  },
  {
      name: <H4>Extend Due Days</H4>,
      id: 'skip_due',
      selector: row => row.Expire_days,
      sortable: true,
      width: '150px',
      wrap: true
  },
];

export const CompanyColumns = (industryUserData, deleteCompanyList) => [
  {
      name: <H4>Sl</H4>,
      selector: row => row.Si,
      sortable: true,
      width: '70px'
  },
  {
      name: <i className="fa fa-gears" style={{ fontSize: '15px' }}></i>,
      selector: row => row.action,
      cell: row => <CompanyActionCell row={row} deleteCompany={deleteCompanyList} companyData={industryUserData}/>,
      center: true,
      width: '55px',

  },
  {
      name: <H4>Company Name</H4>,
      selector: row => row.Company,
      sortable: true,
      width: '190px',
      wrap: true,
  },
  {
      name: <H4>User Name</H4>,
      selector: row => <Link to={`${process.env.PUBLIC_URL}/companies/company-list/userProfile?id=${row.id}`}>{row.Name}</Link>,
      sortable: true,
      width: '110px',
      wrap: true,

  },
  {
      name: <H4>Email</H4>,
      selector: row => row.Email,
      sortable: true,
      width: '190px',
      wrap: true,
  }, {
      name: <H4>SMS</H4>,
      selector: row => row.Sms,
      cell: row => (
          <span
              className={`badge badge-${row.Sms}`}
          >
              {row.Sms}
          </span>
      ),
      sortable: true,
      width: '80px',
      wrap: true,
  },
  {
      name: <H4>Phone No.</H4>,
      selector: row => "+" + row.Mobile,
      sortable: true,
      width: '150px',
      wrap: true,

  },
  {
      name: <H4>Industry</H4>,
      selector: row => row.Industry,
      sortable: true,
      width: '100px',
      wrap: true,

  },
  {
      name: <H4>Package</H4>,
      selector: row => row.Package,
      sortable: true,
      width: '100px',
      wrap: true,

  },
  {
      name: <H4>Start Date</H4>,
      selector: row => row.StartDate,
      sortable: true,
      width: '130px',
      wrap: true,
  },
  {
      name: <H4>End Date</H4>,
      selector: row => row.EndDate,
      sortable: true,
      width: '130px',
      wrap: true,
  },
  {
      name: <H4>Status</H4>,
      selector: row => row.Status,
      cell: row => (
          <span
              className={`badge badge-${row.Status}`}
          >
              {row.Status}
          </span>
      ),
      sortable: true,
      width: '80px',
      wrap: true,
  },
];