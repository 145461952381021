import React, { useState, useEffect, Fragment } from "react";
import { Col, Card, CardHeader, CardBody, Form, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import DataTable from 'react-data-table-component';
import { H3, Breadcrumbs, Btn } from '../../../AbstractElements';
import { useLocation, useNavigate } from 'react-router-dom';
import { postShowTemp } from '../../../CommonURL';
import { getDataWithToken } from '../../../Utils/restUtils';
import { TempDataColumns } from '../../../Data/MockTable/TempData';
import Transparentspinner from "../../Common/loader/Transparentspinner";
import { Filter, Search } from "../../../Constant";

const TempTable = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = location.search;
    const params = new URLSearchParams(searchParams);
    const sortedBy = params.get("sortBy") || '';
    const sortedOrder = params.get("sortOrder") || '';
    const searchKey = params.get('search') || '';
    const [filters, setFilters] = useState({
        search: searchKey,
    });
    const [sortConfig, setSortConfig] = useState({ column: sortedBy, order: sortedOrder });
    const [tempList, setTempList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const { register, setValue, handleSubmit, } = useForm({
        defaultValues: {
            search: filters.search,
        },
    });

    useEffect(() => {
        fetchUsers(1);
    }, []);

    const fetchUsers = async (page, size = perPage) => {
        setLoading(true);
        const response = await getDataWithToken(postShowTemp + `?page=${page}&page_length=${size}&search=${filters.search}&industry_id=`);
        let serialNo = (page - 1) * size + 1;
        setTempList(response.data.data.map((e, index) => {
            return {
                Si: serialNo + index,
                Name: <span>{e.first_name} {e.last_name}</span>,
                Email: e.email,
                Mobile_Number: e.mobile,
                Industry: e.industry_name,
                State: e.state,
                Country: e.country,
            }
        }))
        setLoading(false);
        setTotalRows(response.data.total);
        setLoading(false);
    };

    const onSubmit = async (data) => {
        // e.preventDefault();
        const search = data.search || '';
        const queryParams = new URLSearchParams({
            search: search,
            sortBy: sortConfig.column || '',
            sortOrder: sortConfig.order || '',
        }).toString();
        navigate(`/subscriptions/temp-sub?${queryParams}`);
        setFilters({
            search,
        });
        fetchUsers(1, perPage, search);
    };


    const handlePageChange = page => {
        fetchUsers(page);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        fetchUsers(page, newPerPage);
        setPerPage(newPerPage);
    };

    return (
        <Fragment>
            {loading && <Transparentspinner />}
            <Breadcrumbs mainTitle='Temporary Subscribers' parent="Subscribers" title="Temporary" />
            <Card>
                <CardHeader>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <H3>Temporary Subscribers List</H3>
                    </div>
                </CardHeader>
                <CardBody>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Row className="ps-3 gap-sm-3 gap-md-2 mb-4 filterGap">
                            <Col sm="12" md='5' lg='2'>
                                <input name='search' className="form-control" placeholder={Search} id="validationCustom01" type="text"  {...register('search')}
                                />
                            </Col>
                            <Col sm="12" md="5" lg='1' className="custom-filtrs">
                                <Btn attrBtn={{ color: "primary" }} type="submit">{Filter}</Btn>
                            </Col>
                        </Row>
                    </Form>
                    <div className="order-history table-responsive">
                        <DataTable
                            columns={TempDataColumns}
                            data={tempList}
                            pagination
                            paginationServer
                            defaultSortAsc
                            paginationTotalRows={totalRows}
                            paginationDefaultPage={currentPage}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            // selectableRows
                            onSelectedRowsChange={({ selectedRows }) => console.log(selectedRows)}
                        />
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    );


};

export default TempTable;