import React, { useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { Show } from '../../../Constant';
import { useNavigate } from 'react-router';

const InvoiceActionCell = ({ row, viewForm }) => {
    const navigate = useNavigate();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleStyle = { padding: '10px 20px', opacity: '0.8', fontSize: '14px' };
    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    const redirectToView = () => {
        const editFormURL = `${process.env.PUBLIC_URL}/companies/company-list/userProfile/invoice?id=${row.User_id}&order_id=${row.Id}`;
        navigate(editFormURL);
    };

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
            <DropdownToggle color="#00000" style={{ fontSize: '14px', zIndex: 1000 }}>
                <i className="fa fa-ellipsis-v"></i>
            </DropdownToggle>
            <DropdownMenu container="body">
                <DropdownItem style={toggleStyle} onClick={() => redirectToView()}>{Show}</DropdownItem>
                {row.comment === 'subscription-cost' &&
                    <DropdownItem style={toggleStyle} onClick={() => viewForm(row)}>Extend Days</DropdownItem>
                }
                {/* <DropdownItem style={{ padding: '10px 20px', color: 'red', opacity: '0.8', fontSize: '14px', margin: '0' }} onClick={() => deleteCompanyList()}>{Delete}</DropdownItem> */}
            </DropdownMenu>
        </Dropdown >
    );
};

export default InvoiceActionCell;