import React, { useEffect, useState } from 'react'
import { Col, Form, FormGroup, Label, Row } from 'reactstrap';
import Transparentspinner from '../../../Common/loader/Transparentspinner';
import { useForm } from 'react-hook-form';
import { ConfPassword, Email, NewPassword, OldPassword, Password, Save } from '../../../../Constant';
import { Btn } from '../../../../AbstractElements';
import { toast } from 'react-toastify';
import { emailVerification, updatePersonalInfo, uploadPassword } from '../../../../CommonURL';
import { postDataWithToken } from '../../../../Utils/restUtils';


const ChangePasswordComponent = (props) => {
    const propsValue = props.personaldata;
    const [loading, setLoading] = useState(false);
    const [isVerified, setIsVerified] = useState(false);

    const { register, handleSubmit, control, formState: { errors }, setValue, getValues } = useForm();

    useEffect(() => {
        setValue("email", propsValue.email);
    }, [propsValue, setValue]);

    const handleVerifyEmail = async () => {
        setLoading(true);
        const email = getValues("email");
        const response = await postDataWithToken(emailVerification, { email }); // Replace with actual API endpoint
        if (response.data.status) {
            toast.success("Email verified successfully!");
            setIsVerified(true);
        } else {
            toast.error(response.data.message);
        }
        setLoading(false);
    };

    const onSubmit = async (data) => {
        setLoading(true);
        const postRequest = {
            email: data.email,
            old_password: data.oldPassword,
            new_password: data.password,
        };
        const editPersonalInfo = await postDataWithToken(uploadPassword, postRequest);
        if (editPersonalInfo.data.status) {
            toast.success(editPersonalInfo.data.message);
            props.modal(false);
        } else {
            editPersonalInfo?.data?.message.map((msg) => {
                toast.error(msg);
            })
        }
        setLoading(false);
    };

    return (
        <>
            {loading && <Transparentspinner />}
            <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                <Row className="pt-3">
                    <Col md="6">
                        <Label className="form-label" htmlFor="email">{Email}</Label>
                        <input
                            className="form-control"
                            id="email"
                            type="text"
                            {...register("email")}
                        />
                    </Col>
                    <Col md="6" className="d-flex align-items-end">
                        {!isVerified && (
                            <Btn attrBtn={{ color: "primary", onClick: handleVerifyEmail }}>
                                Verify
                            </Btn>
                        )}
                    </Col>
                </Row>

                {isVerified && (
                    <Row className="g-3 pt-3">
                        <Col md="6">
                            <Label className="form-label" htmlFor="oldPassword">{OldPassword}</Label>
                            <input
                                className="form-control"
                                id="oldPassword"
                                type="password"
                                {...register("oldPassword", { required: "Old password is required" })}
                            />
                            <p className="text-danger">{errors.oldPassword?.message}</p>
                        </Col>
                        <Col md="6">
                            <Label className="form-label" htmlFor="password">{NewPassword}</Label>
                            <input
                                className="form-control"
                                id="password"
                                type="password"
                                {...register("password", { required: "New password is required" })}
                            />
                            <p className="text-danger">{errors.password?.message}</p>
                        </Col>
                        <Col md="6">
                            <Label className="form-label" htmlFor="confPassword">{ConfPassword}</Label>
                            <input
                                className="form-control"
                                id="confPassword"
                                type="password"
                                {...register("confPassword", {
                                    required: "Confirm password is required",
                                    validate: (value) =>
                                        value === getValues("password") || "Passwords do not match"
                                })}
                            />
                            <p className="text-danger">{errors.confPassword?.message}</p>
                        </Col>
                    </Row>
                )}

                {isVerified && (
                    <FormGroup>
                        <Btn attrBtn={{ color: "primary" }} type="submit">
                            {Save}
                        </Btn>
                    </FormGroup>
                )}
            </Form>
        </>
    );
}

export default ChangePasswordComponent;