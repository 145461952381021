import React, { Fragment, useState, useEffect, useRef, useReducer } from 'react';
import { Col, Card, CardHeader, CardBody, Form, Row } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { H3, Btn, Breadcrumbs } from '../../../AbstractElements';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import SweetAlert from 'sweetalert2';
import { getIndustryUser } from '../../../CommonURL';
import { getDataWithToken } from '../../../Utils/restUtils';
import { Add, Filter, Status } from '../../../Constant';
import Transparentspinner from '../../Common/loader/Transparentspinner';
import { useForm } from 'react-hook-form';
import Progress from '../../Common/Progress';
import { EarningsSvg } from '../../../Data/Widgets/General/Data';
import CommonModalCancel from '../../UiKits/Modals/common/modalcncl';
import DeleteCustomer from './DeleteCustomer';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { statusOptions } from '../../../Constant/DropdownValue';
import CompanyFilter from '../../Common/Filters/CompanyFilter';
import SearchFilter from '../../Common/Filters/Search';
import { CompanyColumns } from '../../../Data/MockTable/TempData';
import { initialState, reducer } from './State';

const CompanyTable = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isStatus = useRef(null);
    const [state, dispatch] = useReducer(reducer, initialState);
    const { filters, dateRange, pagination, loading, companyList, staticWidgetsData } = state;
    const [startDate, endDate] = dateRange;
    const searchParams = location.search;
    const params = new URLSearchParams(searchParams);
    const sortedBy = params.get("sortBy") || '';
    const sortedOrder = params.get("sortOrder") || '';
    const [sortConfig, setSortConfig] = useState({ column: sortedBy, order: sortedOrder });
    const [customerId, setCustomerId] = useState('');
    const [isCompany, setIsCompany] = useState('');
    const { handleSubmit, } = useForm({
        defaultValues: {
            search: filters.search,
        },
    });

    useEffect(() => {
        industryUserData(1, pagination.perPage, filters.search);
    }, [filters]);

    const handlePageChange = page => {
        industryUserData(page);
        dispatch({ type: "SET_PAGINATION", payload: { page } });
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        industryUserData(page, newPerPage);
        dispatch({ type: "SET_PAGINATION", payload: { perPage: newPerPage } });
    };

    const deleteCompanyList = (userId) => {
        SweetAlert.fire({
            title: 'Are you sure?',
            text: 'Once deleted, you will not be able to recover this customer!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'cancel',
            reverseButtons: true
        }).then(async (result) => {
            dispatch({ type: "SET_LOADING", payload: true });
            if (result.value) {
                setCustomerId(userId);
                dispatch({ type: "TOGGLE_MODAL" });
            } else {
                // navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
            }
            dispatch({ type: "SET_LOADING", payload: false });
        });
    };

    const industryUserData = async (page, size = pagination.perPage, serachValue = filters.search) => {
        dispatch({ type: "SET_LOADING", payload: true });
        const response = await getDataWithToken(`${getIndustryUser}?page=${page}&page_length=${size}&search=${serachValue}
            &start_date=${filters.start_date}&end_date=${filters.end_date}&status=${filters.status}&company_id=${filters.companyId ? filters.companyId : ''}`);
        const SerialNo = (page - 1) * size + 1;
        dispatch({
            type: "SET_COMPANY_LIST",
            payload: response.data.users.data.map((e, index) => ({
                Si: SerialNo + index,
                Name: <div>{e.user_name}</div>,
                Company: e.company,
                CompanyId: e.company_id,
                Email: e.email,
                Sms: e.sms_enabled,
                Mobile: e.mobile,
                Industry: e.industry_name,
                Package: e.package_name,
                StartDate: e.start_date,
                EndDate: e.end_date,
                Status: e.status,
                id: e.id,
            })),
        })
        dispatch({
            type: "SET_STATIC_WIDGETS", payload: [
                { id: 1, title: "Total", counter: response.data.total_count, svg: <EarningsSvg />, class: 'progress-gradient-secondary', progress: '75%' },
                { id: 2, title: "Total Free", counter: response.data.total_free_count, svg: <EarningsSvg />, class: 'progress-gradient-secondary', progress: '75%' },
                { id: 3, title: "Total Paid", counter: response.data.total_paid_count, svg: <EarningsSvg />, class: 'progress-gradient-secondary', progress: '75%' },
            ]
        });
        dispatch({ type: "SET_PAGINATION", payload: { totalRows: response.data.users.total } });
        dispatch({ type: "SET_LOADING", payload: false });
    };

    const onSubmit = async (data) => {
        const search = state.tempSearch;  // Keep the latest search value
        const start_date = startDate ? moment(startDate).format("DD-MM-YYYY") : '';
        const end_date = endDate ? moment(endDate).format("DD-MM-YYYY") : '';
        dispatch({
            type: "SET_FILTERS",
            payload: {
                search,
                start_date,
                end_date,
                status: isStatus.current.value,
                companyId: isCompany?.value
            }
        });
    };

    return (
        <Fragment>
            {loading && <Transparentspinner />}
            <Breadcrumbs mainTitle='Subscriptions' parent="Subscriptions" title="Subscriptions" />
            <Progress details={staticWidgetsData} />
            <Card>
                <CardHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <H3>Active Subscribers List</H3>
                    <div>
                        <Link to={`${process.env.PUBLIC_URL}/companies/company-add`} className="btn btn-primary">{Add}</Link>
                    </div>
                </CardHeader>
                <CommonModalCancel
                    isOpen={state.modal}
                    title='Delete Customer'
                    toggler={() => dispatch({ type: "TOGGLE_MODAL" })}
                    size="lg"
                >
                    <DeleteCustomer
                        id={customerId}
                        modal={() => dispatch({ type: "TOGGLE_MODAL" })}
                        list={industryUserData}
                    />
                </CommonModalCancel>
                <CardBody>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Row className="ps-3 gap-sm-3 gap-md-2 mb-4 filterGap">
                            <SearchFilter
                                searchValue={state.tempSearch}
                                onSearchChange={(val) => dispatch({ type: "SET_TEMP_SEARCH", payload: val })}
                            />
                            <Col sm="12" md="5" lg='2' className="form-element" >
                                <div style={{ position: 'relative', zIndex: 2 }}>
                                    <DatePicker
                                        className="form-control digits w-100"
                                        selectsRange
                                        startDate={startDate}
                                        endDate={endDate}
                                        onChange={(update) => dispatch({ type: "SET_DATE_RANGE", payload: update })}
                                        isClearable
                                        monthsShown={2}
                                        popperPlacement="bottom"
                                        calendarClassName='parallel-calendar'
                                        placeholderText='Select Date Range'
                                    />
                                </div>
                            </Col>
                            <Col sm="12" md="5" lg="3" className="form-element">
                                <CompanyFilter setIsCompany={setIsCompany} />
                            </Col>
                            <Col sm="12" md="5" lg='2' className="form-element">
                                <select className="form-select" ref={isStatus}>
                                    <option value="">{Status}</option>
                                    {statusOptions.map((option, index) => (
                                        <option key={index} value={option.value}>{option.label}</option>
                                    ))}
                                </select>
                            </Col>
                            <Col sm="12" md="5" lg='1' className="custom-filtrs">
                                <Btn attrBtn={{ color: "primary" }} type="submit">{Filter}</Btn>
                            </Col>
                        </Row>
                    </Form>
                    <div className="order-history table-responsive">
                        <DataTable
                            columns={CompanyColumns(industryUserData, deleteCompanyList)}
                            data={companyList}
                            pagination
                            paginationServer
                            defaultSortAsc
                            paginationTotalRows={pagination.totalRows}
                            paginationDefaultPage={pagination.page}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                        />
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    );
};

export default CompanyTable;