import React, { useEffect, useState } from 'react'
import { Col, Form, FormGroup, Label, Row } from 'reactstrap';
import Transparentspinner from '../../../Common/loader/Transparentspinner';
import { Controller, useForm } from 'react-hook-form';
import { City, Country, FirstName, LastName, Save, State } from '../../../../Constant';
import { useLocation } from 'react-router';
import { Btn } from '../../../../AbstractElements';
import PhoneInput from 'react-phone-input-2';
import { toast } from 'react-toastify';
import { updatePersonalInfo } from '../../../../CommonURL';
import { postDataWithToken } from '../../../../Utils/restUtils';


const EditProfile = (props) => {
    const e = props.personaldata;
    const [loading, setLoading] = useState(false)
    const { register, handleSubmit, control, formState: { errors }, setValue } = useForm();
    const location = useLocation();
    const searchParams = location.search;
    const params = new URLSearchParams(searchParams);
    const param1 = params.get("id");

    useEffect(() => {
        handleEditView();
    }, []);

    const handleEditView = () => {
        setValue("fname", e.first_name);
        setValue("lname", e.last_name);
        setValue("phonenumber", e.country_code + e.mobile);
        setValue("country", e.country);
        setValue("state", e.state);
        setValue("city", e.city);
        setValue("countryCode", e.country_code);
    }
    const onSubmit = async (data) => {
        const countryCode = data.countryCode;
        const phoneNumber = data.phonenumber;
        if (phoneNumber.startsWith(countryCode)) {
            setLoading(true);
            const b = phoneNumber.slice(countryCode.length);
            const postRequest = {
                user_id: param1,
                first_name: data.fname,
                last_name: data.lname,
                country_code: countryCode,
                mobile: b,
                country: data.country,
                state: data.state,
                city: data.city,
            };
            const editPersonalInfo = await postDataWithToken(updatePersonalInfo, postRequest);
            if (editPersonalInfo.data.status) {
                toast.success(editPersonalInfo.data.message);
            } else {
                setLoading(false);
                toast.error(editPersonalInfo.data.message);
            }

        } else {
            setLoading(false);
            toast.error("phone/country code missing")
        }
        setLoading(false);


    }

    return (
        <>
            {loading && <Transparentspinner />}
            <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                <Row className="pt-3">
                    <Col md="6">
                        <Label className="form-label" for="first_name">{FirstName}</Label>
                        <input className="form-control" id="first_name" type="text" {...register("fname")} />
                        {/* <p className="text-danger">{errors.fname?.message}</p> */}
                    </Col>
                    <Col md="6">
                        <Label className="form-label" for="last_name">{LastName}</Label>
                        <input className="form-control" id="last_name" type="text" {...register("lname")} />
                        {/* <p className="text-danger">{errors.lname?.message}</p> */}
                    </Col>
                </Row>
                <Row className=" pt-3">
                    <Col md="6">
                        <Controller
                            name="phonenumber"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <PhoneInput
                                    {...field}
                                    country={"in"}
                                    enableSearch={true}
                                    onChange={(value, country) => {
                                        setValue('countryCode', country.dialCode);
                                        field.onChange(value);
                                    }}
                                    inputStyle={{
                                        width: "100%",
                                        height: "46px",
                                        padding: "10px",
                                        paddingLeft: "50px",
                                        fontSize: "16px",
                                        borderRadius: "8px",
                                        borderColor: "rgba(83, 70, 134, 0.1019607843)",
                                    }}
                                    searchClass="none"
                                />
                            )}
                        />
                        <span className="text-danger">
                            {/* {errors.mobile && MobileNoError} */}
                        </span>
                    </Col>
                    <Col md="6">
                        <Label className="form-label" for="last_name">{Country}</Label>
                        <input className="form-control" id="country" type="text" {...register("country")} />
                        {/* <p className="text-danger">{errors.lname?.message}</p> */}
                    </Col>
                </Row>
                <Row className="g-3 pt-3">
                    <Col md="6">
                        <Label className="form-label" for="state">{State}</Label>
                        <input className="form-control" id="state" type="text" {...register("state")} />
                        {/* <p className="text-danger">{errors.fname?.message}</p> */}
                    </Col>
                    <Col md="6">
                        <Label className="form-label" for="last_name">{City}</Label>
                        <input className="form-control" id="city" type="text" {...register("city")} />
                        {/* <p className="text-danger">{errors.lname?.message}</p> */}
                    </Col>
                </Row>
                <FormGroup></FormGroup>
                <Btn attrBtn={{ color: "primary" }} type="submit">
                    {Save}
                </Btn>
            </Form>
        </>
    )
}

export default EditProfile