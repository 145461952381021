import React, { Fragment } from "react";
import { Container, Row } from "reactstrap";
import { Breadcrumbs } from "../../../AbstractElements";
import CountrySales from "./CountrySales";
import ItemsSlider from "./ItemSlider";
import SaleChart from "./SaleChart";
import RecentSubscription from "./RecentSubscription";
import RecentPayment from "./RecentPayment";
import Payment from "./Payment";
import ExpiredSub from "./ExpireSub";

const Ecommerce = () => {
    return (
        <Fragment>
            <Breadcrumbs mainTitle="Ecommerce Dashboard" title="Ecommerce" parent="Dashboard" />
            <Container fluid={true} className="ecommerce-page">
                <Row>
                    <SaleChart />
                    <ExpiredSub />
                    <ItemsSlider />
                    <Payment />
                    <RecentSubscription />
                    <RecentPayment />
                    <CountrySales />
                </Row>
            </Container>
        </Fragment>
    );
};

export default Ecommerce;