import React, { Fragment, useEffect, useState } from "react";
import { Card, CardBody, Col, Row, Table } from "reactstrap";
import { H3, H4, H5 } from "../../../AbstractElements";
import { ItemsSliderData } from '../../../Data/Dashboard/Ecommerce/Data';
import Slider from 'react-slick';
import { Amount, CompanyName, EndDate, ExpiresToday, Name } from "../../../Constant";
import HeaderCard from "../../Common/Component/HeaderCard";
import { Link } from "react-router-dom";
import { getDataWithToken } from "../../../Utils/restUtils";
import { getExpireToday } from "../../../CommonURL";
import HandleApiError from "../../Common/HandleError/HandleApiError";
import { toast } from "react-toastify";

const ItemsSlider = () => {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: { slidesToShow: 2, slidesToScroll: 1 }
            },
            {
                breakpoint: 600,
                settings: { slidesToShow: 2, slidesToScroll: 2, initialSlide: 2 }
            },
            {
                breakpoint: 480,
                settings: { slidesToShow: 1, slidesToScroll: 1 }
            }
        ]
    };

    const [expireData, setExpireData] = useState([]);

    useEffect(() => {
        ExpireData();
    }, []);

    const ExpireData = async () => {
        const data = await getDataWithToken(getExpireToday);
        if (data.status) {
            setExpireData(data.data);
        } else {
            toast.error(data.message)
        }
    };

    return (
        <Fragment>
            <Col xxl="4" xl="5" md="7 box-col-40">
                <Slider {...settings} className="items-slider m-0">
                    {ItemsSliderData.map((data) => {
                        return (
                            <Col xl="6" lg="6" md="6" sm="6" key={data.id}>
                                <Card className={`rated-product ${data.color}`}>
                                    <CardBody>
                                        <div className="product-detail">
                                            <H4>{data.title}</H4>
                                            <H4>{data.title2}</H4>
                                            <H3>{data.price}</H3>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        )
                    })}
                </Slider>
                <Fragment>
                    <Col xl="12" md="12 box-col-30">
                        <Card className="transaction-history" style={{ height: '345px' }}>
                            <HeaderCard title={ExpiresToday} setting={true} />
                            <CardBody>
                                <div className="table-responsive sroller-style" style={{ height: '280px', overflowY: 'auto' }}>
                                    <Table className="table table-bordernone table-responsive">
                                        <thead>
                                            <tr>
                                                <th>{Name}</th>
                                                <th>{CompanyName}</th>
                                                <th>{EndDate}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {expireData.map((item, index) => {
                                                return (<tr key={index}>
                                                    <td>
                                                        <Link style={{ color: '#534686', marginLeft: '13px' }} to={`${process.env.PUBLIC_URL}/report/payment-collection?user=${item.agent_id}`}>
                                                            <H5 >{item.user_name}</H5>
                                                        </Link>
                                                    </td >
                                                    <td>
                                                        <H5>{item.company}</H5>
                                                    </td>
                                                    <td>
                                                        <H5>{item.end_date}</H5>
                                                    </td>
                                                </tr>)
                                            })}
                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                        </Card>

                    </Col>

                </Fragment>
                <Row>
                    <Col xl="12">
                        {/* <Card className="product-review">
                            <CardBody> */}
                        {/* <div className="d-flex"> */}
                        {/* <div className="flex-shrink-0">
                                        <div className="img-wrapper">
                                            <Image attrImage={{ className: "img-fluid", src: `${require("../../../assets/images/dashboard-2/person1.png")}`, alt: "person1" }} />
                                        </div>
                                    </div> */}
                        {/* <div className="person-detail">
                                        <H4>{JohannaParvez}</H4>
                                        <UL attrUL={{ className: "simple-list rating-star flex-row " }} >
                                            <LI><i className="fa fa-star me-0"></i></LI>
                                            <LI><i className="fa fa-star me-0"></i></LI>
                                            <LI><i className="fa fa-star me-0"></i></LI>
                                            <LI><i className="fa fa-star me-0"></i></LI>
                                            <LI><i className="fa fa-star me-0"></i></LI>
                                        </UL>
                                        <i className="fa fa-quote-right fa-5x quote-icon"></i>
                                    </div> */}
                        {/* </div> */}
                        {/* <div className="product-detail">
                                    <P>{ProductDetail}</P>
                                </div> */}
                        {/* </CardBody>
                        </Card> */}
                    </Col>
                </Row>
            </Col>
        </Fragment>
    );
};
export default ItemsSlider;