import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Col, Card, CardHeader, CardBody, Form, Row } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { H3, Btn, Breadcrumbs } from '../../../AbstractElements';
import { useLocation, useNavigate } from 'react-router-dom';
import { getShowOrder } from '../../../CommonURL';
import { getDataWithToken } from '../../../Utils/restUtils';
import { ExtendDays, Filter, PaymentStatus } from '../../../Constant';
import Transparentspinner from '../../Common/loader/Transparentspinner';
import PopupDays from '../../Companies/companies/PopupDays';
import Popup from '../../Common/Popup/Popup';
import { useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { invoicePaymentOptions } from '../../../Constant/DropdownValue';
import CompanyFilter from '../../Common/Filters/CompanyFilter';
import SearchFilter from '../../Common/Filters/Search';
import { InvoiceDataColumns } from '../../../Data/MockTable/TempData';

const InvoiceTable = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isStatus = useRef(null);
    const searchParams = location.search;
    const params = new URLSearchParams(searchParams);
    const sortedBy = params.get("sortBy") || '';
    const sortedOrder = params.get("sortOrder") || '';
    const [filters, setFilters] = useState({
        search: params.get('search') || '',
        status: params.get('status') || '',
        companyId: params.get('companyId') || '',
        start_date: params.get('start_date') ? moment(params.get('start_date'), "DD-MM-YYYY").toDate() : '',
        end_date: params.get('end_date') ? moment(params.get('end_date'), "DD-MM-YYYY").toDate() : '',
    });
    const [dateRange, setDateRange] = useState([filters.start_date, filters.end_date]);
    const [startDate, endDate] = dateRange
    const [invoiceList, setInvoiceList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [orderId, setOrderId] = useState('');
    const [modal, setModal] = useState(false);
    const [sortConfig, setSortConfig] = useState({ column: sortedBy, order: sortedOrder });
    const [currentPage, setCurrentPage] = useState(1);
    const [isCompany, setIsCompany] = useState('');
    const formatStartDate = filters.start_date ? moment(filters.start_date).format("DD-MM-YYYY") : '';
    const formatEndDate = filters.end_date ? moment(filters.end_date).format("DD-MM-YYYY") : '';
    const { register, setValue, handleSubmit, } = useForm({
        defaultValues: {
            search: filters.search,
        },
    });

    useEffect(() => {
        invoiceDataTable(1); // do not put await here
    }, []);

    useEffect(() => {
        setValue('search', filters.search);
    }, [setValue, filters.search]);


    const viewForm = (row) => {
        setModal(!modal);
        setOrderId(row);
    };

    const handlePageChange = page => {
        invoiceDataTable(page);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        invoiceDataTable(page, newPerPage);
        setPerPage(newPerPage);
    };

    const onSubmit = async (data) => {
        // e.preventDefault();
        const search = data.search || '';
        const start_date = startDate ? moment(startDate).format("DD-MM-YYYY") : '';
        const end_date = endDate ? moment(endDate).format("DD-MM-YYYY") : '';
        const status = isStatus.current.value;
        const companyId = isCompany?.value;
        const queryParams = new URLSearchParams({
            search: search,
            start_date,
            end_date,
            status: status,
            companyId: companyId,
            sortBy: sortConfig.column || '',
            sortOrder: sortConfig.order || '',
        }).toString();
        navigate(`/billing/invoice-list?${queryParams}`);
        setFilters({
            search,
            start_date,
            end_date,
            companyId,
            status
        });
        invoiceDataTable(currentPage, perPage);
    };

    const handleSort = (column) => {
        let newOrder = 'asc';
        if (filters.sortBy === column.id) {
            newOrder = filters.sortOrder === 'asc' ? 'desc' : 'asc';
            setSortConfig({ column: column.id, order: newOrder });
            navigate(`/billing/invoice-list?search=${filters.search}&start_date=${formatStartDate}&end_date=${formatEndDate}&payment_status=${filters.status}&sortBy=${column.id}&sortOrder=${newOrder}`);
        } else {
            newOrder = filters.sortOrder === 'asc' ? 'desc' : 'asc';
            setSortConfig({ column: column.id, order: newOrder });
            navigate(`/billing/invoice-list?search=${filters.search}&start_date=${formatStartDate}&end_date=${formatEndDate}&payment_status=${filters.status}&sortBy=${column.id}&sortOrder=${newOrder}`);
        }
    };

    const invoiceDataTable = async (page, size = perPage) => {
        setLoading(true);
        const response = await getDataWithToken(getShowOrder + `?page=${page}&page_length=${size}&search=${filters.search}
            &start_date=${formatStartDate}&end_date=${formatEndDate}&payment_status=${filters.status}&company_id=${filters.companyId}
            &sort_by=${sortConfig.column ? sortConfig.column : ''}&sort_order=${sortConfig.order ? sortConfig.order : ''}`);
        let serialNo = (page - 1) * size + 1;
        setInvoiceList(response.data.data.map((e, index) => {
            return {
                Si: serialNo + index,
                Invoice_no: <span>{e.invoice_prefix} {e.invoice_number}</span>,
                Invoiced_at: e.invoice_date,
                End_at: e.end_date,
                User_name: e.user_name,
                Company_name: e.company_name,
                Amount: e.amount,
                Balance: e.balance,
                Payment_status: e.payment_status,
                Status: e.order_status,
                Id: e.id,
                User_id: e.user_id,
                comment: e.comments,
                Expire_days: e.skip_due,
            }
        }))
        setTotalRows(response.data.total);
        setLoading(false);
    };

    return (
        <Fragment>
            {loading && <Transparentspinner />}
            <Breadcrumbs mainTitle='Invoice' parent="Configuration" title="Invoice" />
            <Card>
                <CardHeader>
                    <H3>Invoice List</H3>
                </CardHeader>
                <CardBody>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Row className="ps-3 gap-sm-3 gap-md-2 mb-4 filterGap">
                            <SearchFilter
                                searchValue={filters.search}
                                onSearchChange={(val) => setFilters({ ...filters, search: val })}
                            />
                            <Col sm="12" md="5" lg='2' className="form-element" >
                                <div style={{ position: 'relative', zIndex: 2 }}>
                                    <DatePicker
                                        className="form-control digits w-100"
                                        selectsRange
                                        startDate={startDate}
                                        endDate={endDate}
                                        onChange={(update) => {
                                            setDateRange(update);
                                        }}
                                        isClearable
                                        monthsShown={2}
                                        popperPlacement="bottom"
                                        calendarClassName='parallel-calendar'
                                        placeholderText='Select Date Range'
                                    />
                                </div>
                            </Col>
                            <Col sm="12" md="5" lg="3" className="form-element">
                                <CompanyFilter setIsCompany={setIsCompany} />
                            </Col>
                            <Col sm="12" md="5" lg='2' className="form-element">
                                <select className="form-select" ref={isStatus}>
                                    <option value="">{PaymentStatus}</option>
                                    {invoicePaymentOptions.map((option, index) => (
                                        <option key={index} value={option.value}>{option.label}</option>
                                    ))}
                                </select>
                            </Col>
                            <Col sm="12" md="5" lg='1' className="custom-filtrs">
                                <Btn attrBtn={{ color: "primary" }} type="submit">{Filter}</Btn>
                            </Col>
                        </Row>
                    </Form>
                    <div className="order-history table-responsive">
                        <DataTable
                            columns={InvoiceDataColumns(viewForm)}
                            data={invoiceList} onSort={handleSort}
                            sortServer
                            pagination
                            paginationServer
                            defaultSortAsc
                            paginationTotalRows={totalRows}
                            paginationDefaultPage={currentPage}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                        />
                    </div>
                    <Popup isOpen={modal} title={ExtendDays} toggler={viewForm}>
                        <PopupDays orderId={orderId} setModal={setModal} invoice={invoiceDataTable} />
                    </Popup>
                </CardBody>
            </Card>
        </Fragment>
    );
};

export default InvoiceTable;