import React, { Fragment, useEffect, useState } from "react";
// import { Card, CardBody, CardHeader, Col } from "reactstrap";
import { Card, CardBody, Col, Table } from "reactstrap";
import { H5, P } from "../../../AbstractElements";
import HeaderCard from "../../Common/Component/HeaderCard";
import { Recentsubscribers, Name, Status, Email, Phone } from "../../../Constant";
import { Link } from 'react-router-dom';
import { getRecentSubscription } from "../../../CommonURL";
import { getDataWithToken } from "../../../Utils/restUtils";
import { toast } from "react-toastify";

const RecentSubscription = () => {
    const [subscriptionData, setSubscriptionData] = useState([]);

    useEffect(() => {
        recentSubData();
    }, []);

    const recentSubData = async () => {
        const data = await getDataWithToken(getRecentSubscription);
        if (data.status) {
            setSubscriptionData(data.data);
        } else {
            toast.error(data.message)
        }
    };

    return (
        <Fragment>
            <Col xxl="5" xl="4" lg="12" sm="6 box-col-30">
                <Card className="transaction-history" style={{ height: '420px' }}>
                    <HeaderCard title={Recentsubscribers} setting={true} />
                    <CardBody>
                        <div className="table-responsive sroller-style" style={{ height: '355px', overflowY: 'auto' }}>
                            <Table className="table table-bordernone table-responsive">
                                <thead>
                                    <tr>
                                        <th>{Name}</th>
                                        <th>{Email}</th>
                                        <th>{Phone}</th>
                                        <th>{Status}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {subscriptionData.map((item, index) => {
                                        return (<tr key={index}>
                                            <td>
                                                <Link style={{ color: '#534686', marginLeft: '13px' }} to={`${process.env.PUBLIC_URL}/report/payment-collection?user=${item.agent_id}`}>
                                                    <H5 >{item.user_name}</H5>
                                                </Link>
                                            </td >
                                            <td>
                                                <H5>{item.email}</H5>
                                            </td>
                                            <td>
                                                <H5>{item.mobile}</H5>
                                            </td>
                                            <td>
                                                <div>
                                                    <P attrPara={{ className: `badge badge-${item.plan_type}`, style: { color: 'white' } }}>{item.plan_type}</P>
                                                    {/* <div className={`progress ${item.class}`} style={{ height: "5px" }}>
                                                        <div className="progress-bar" role="progressbar" style={{ width: `${item.progress}` }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div > */}
                                                </div >
                                            </td >
                                        </tr >)
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                </Card>
            </Col>
            {/* <Col xxl="5" lg="4" sm="6 box-col-40">
                <Card className="recent-order">
                    <CardHeader className="pb-0">
                        <H3>{RecentOrders}</H3>
                    </CardHeader>
                    <CardBody className="pb-0">
                        <div className="medium-chart">
                            <div className="recent-chart" id="recent-chart">
                                <Chart options={RecentChart.options} series={RecentChart.series} type="area" height={355} />
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col> */}
        </Fragment>
    );
};

export default RecentSubscription;