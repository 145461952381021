export const statusOptions = [
    { value: "activate", label: "Active" },
    { value: "inactive", label: "Inactive" },
    { value: "suspended", label: "Suspended" },
    { value: "expired", label: "Expired" }
];

export const invoicePaymentOptions = [
    { value: "paid", label: "Paid" },
    { value: "partially_paid", label: "Partially Paid" },
    { value: "pending", label: "Pending" },
];

export const paymentStatusOptions = [
    { value: "complete", label: "Complete" },
    { value: "pending", label: "Pending" },
    { value: "failed", label: "Failed" },
]