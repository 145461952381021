import React, { Fragment, useState } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { createInvoice } from '../../CommonURL';
import { postDataWithToken } from '../../Utils/restUtils';
import Transparentspinner from '../../Components/Common/loader/Transparentspinner';
import { toast } from 'react-toastify';

const NavCustomizer = ({ callbackNav, selected }) => {
    const [loading, setLoading] = useState(false);
    const apiGenerate = async () => {
        setLoading(true);
        const responce = await postDataWithToken(createInvoice);
        if (responce?.status === 200) {
            toast.success(responce.data.message)
            setLoading(false);
        } else {
            toast.error('something wrong.');
            setLoading(false);
        }
    }
    return (
        <Fragment>
            {loading && <Transparentspinner />}
            <Nav className="flex-column nac-pills" id="c-pills-tab" role="tablist" aria-orientation="vertical">
                {/* <NavItem>
                    <NavLink className={selected === 'color-picker' ? 'active' : ''} onClick={() => callbackNav('color-picker', true)}>
                        <div className="settings"><i className="icon-paint-bucket"></i></div>
                    </NavLink>
                </NavItem> */}
                <NavItem>
                    <NavLink className={selected === 'sidebar-type' ? 'active' : ''} onClick={() => apiGenerate()}>
                        <div className="settings"><i className="text-center">
                            <div className='qpay-rightbar pt-1 fw-bold'>
                                <b> Generate Invoice</b>
                            </div>
                        </i></div>
                    </NavLink>
                </NavItem>
                {/* <NavItem>
                    <NavLink className={selected === 'sidebar-type' ? 'active' : ''} onClick={() => callbackNav('sidebar-type', true)}>
                        <div className="settings"><i className="icon-settings"></i></div>
                    </NavLink>
                </NavItem> */}
            </Nav>
        </Fragment>
    );
};

export default NavCustomizer;