export const initialState = {
    filters: {
        search: "",
        status: "",
        companyId: "",
        start_date: "",
        end_date: "",
    },
    tempSearch: '',
    dateRange: [null, null],
    pagination: { page: 1, perPage: 10, totalRows: 0 },
    loading: false,
    modal: false,
    customerId: "",
    companyList: [],
    staticWidgetsData: [],
};


export const reducer = (state, action) => {
    switch (action.type) {
        case "SET_FILTERS":
            return { ...state, filters: { ...state.filters, ...action.payload } };
        case "SET_TEMP_SEARCH":
            return { ...state, tempSearch: action.payload };
        case "SET_DATE_RANGE":
            return { ...state, dateRange: action.payload };
        case "SET_LOADING":
            return { ...state, loading: action.payload };
        case "SET_COMPANY_LIST":
            return { ...state, companyList: action.payload };
        case "SET_PAGINATION":
            return { ...state, pagination: { ...state.pagination, ...action.payload } };
        case "TOGGLE_MODAL":
            return { ...state, modal: !state.modal, customerId: action.payload || '' };
        case "SET_CUSTOMER_ID":
            return { ...state, customerId: action.payload };
        case "SET_STATIC_WIDGETS":
            return { ...state, staticWidgetsData: action.payload };
        default:
            return state;
    }
};