import React, { useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { Active, Delete, LoginAs, Show, SmsStatus, Suspend } from '../../../Constant';
import { useNavigate } from 'react-router';
import { postDataWithoutToken, postDataWithToken } from '../../../Utils/restUtils';
import { getOtp, OperatorUrl, postActiveCustomer, postSmsStatus, postSuspendCustomer } from '../../../CommonURL';
import SweetAlert from 'sweetalert2';
import Transparentspinner from '../../Common/loader/Transparentspinner';
import Popup from '../../Common/Popup/Popup';
import PopupDays from './PopupDays';
import DeleteCompany from './DeleteCompany';

const CompanyActionCell = ({ row, deleteCompany, companyData }) => {
    const navigate = useNavigate();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const toggleStyle = { padding: '10px 20px', opacity: '0.8', fontSize: '14px' }
    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    const viewForm = (row) => {
        setModal(!modal);
    };

    const redirectToLogin = async (row) => {
        const userId = { user_id: row.id }
        const token = await postDataWithoutToken(getOtp, userId);
        if (token.data.status === true) {
            const url = OperatorUrl + `?user_id=${row.id}&token=${token.data.data}`;
            window.open(url, '_blank');
        }
    };

    const SmsStatusChange = async (data) => {
        SweetAlert.fire({
            title: 'Are you sure?',
            text: 'You want to change the status of SMS!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'cancel',
            reverseButtons: true
        }).then(async (result) => {
            setLoading(true);
            if (result.value) {
                const payload = { company_id: data.CompanyId, sms_enable: data.Sms === 'no' ? 'yes' : 'no' }
                const packageRemove = await postDataWithToken(postSmsStatus, payload);
                if (packageRemove.data.status === true) {
                    SweetAlert.fire(
                        'Status Changed!',
                        'Your status has been changed.',
                        'success'
                    );
                    companyData();
                } else {
                    navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
                    setLoading(false);
                }
            } else {
                // navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
            }
            setLoading(false);
        });
    };

    const suspendCustomer = async (data) => {
        SweetAlert.fire({
            title: 'Are you sure?',
            text: 'You want to suspend customer!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'cancel',
            reverseButtons: true
        }).then(async (result) => {
            setLoading(true);
            if (result.value) {
                const payload = { user_id: data.id }
                const packageRemove = await postDataWithToken(postSuspendCustomer, payload);
                if (packageRemove.data.status === true) {
                    SweetAlert.fire(
                        'Status Changed!',
                        'Your status has been changed.',
                        'success'
                    );
                    companyData();
                } else {
                    navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
                    setLoading(false);
                }
            } else {
                // navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
            }
            setLoading(false);
        });
    };

    const activeCustomer = async (data) => {
        SweetAlert.fire({
            title: 'Are you sure?',
            text: 'You want to active customer!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'cancel',
            reverseButtons: true
        }).then(async (result) => {
            setLoading(true);
            if (result.value) {
                const payload = { user_id: data.id }
                const packageRemove = await postDataWithToken(postActiveCustomer, payload);
                if (packageRemove.data.status === true) {
                    SweetAlert.fire(
                        'Status Changed!',
                        'Your status has been changed.',
                        'success'
                    );
                    companyData();
                } else {
                    navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
                    setLoading(false);
                }
            } else {
                // navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
            }
            setLoading(false);
        });
    };

    const companyDelete = async () => {
        SweetAlert.fire({
            title: 'Are you sure?',
            text: 'Once deleted, you will not be able to recover this company!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'cancel',
            reverseButtons: true
        }).then(async (result) => {
            setLoading(true);
            if (result.value) {
                setModal(true); 
            } else {
                // navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
            }
            setLoading(false);
        });
    };

    return (
        <>
            {loading && <Transparentspinner />}
            <Popup isOpen={modal} title={`Company `+Delete} toggler={viewForm}>
                <DeleteCompany id={row.CompanyId} modal={setModal} list={companyData || (() => {})} />
            </Popup>
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle color="#00000" style={{ fontSize: '14px', zIndex: 1000 }}>
                    <i className="fa fa-ellipsis-v"></i>
                </DropdownToggle>
                <DropdownMenu container="body">
                    {/* <DropdownItem style={toggleStyle} onClick={() => redirectToEditForm(row)}>{Edit}</DropdownItem> */}
                    <DropdownItem style={toggleStyle} onClick={() => SmsStatusChange(row)}>{SmsStatus}</DropdownItem>
                    <DropdownItem style={toggleStyle} onClick={() => redirectToLogin(row)}>{LoginAs}</DropdownItem>
                    <DropdownItem style={toggleStyle} onClick={() => suspendCustomer(row)}>{Suspend}</DropdownItem>
                    <DropdownItem style={toggleStyle} onClick={() => activeCustomer(row)}>{Active}</DropdownItem>
                    <DropdownItem style={{ padding: '10px 20px', color: 'red', opacity: '0.8', fontSize: '14px', margin: '0' }} onClick={() => companyDelete()}>Company {Delete}</DropdownItem>
                    <DropdownItem style={{ padding: '10px 20px', color: 'red', opacity: '0.8', fontSize: '14px', margin: '0' }} onClick={() => deleteCompany(row.id)}>Customer {Delete}</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </>
    );
};

export default CompanyActionCell;