import React, { useEffect, useState } from 'react';
import Typeahead from '../../../CommonElements/TypeAhead';
import { getDataWithToken } from '../../../Utils/restUtils';
import { getCompanyList } from '../../../CommonURL';

function CompanyFilter({setIsCompany}) {
    const [customerOptions, setCustomerOptions] = useState([]);

    useEffect(() => {
        customerData();
    }, []);

    const customerData = async () => {
        const data = await getDataWithToken(getCompanyList);
        if (data.status) {
            setCustomerOptions(
                data.data.map(({ id, name }) => ({ value: id, label: name }))
            );
        } else {
            handleApiError(data.status);
        }
    };

    const handleChange = (selectedOption) => setIsCompany(selectedOption);
    return (
        <Typeahead
            options={customerOptions}
            onChange={handleChange}
            placeholder="Company..."
        />
    )
}

export default CompanyFilter