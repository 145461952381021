import React, { Fragment, useEffect, useState } from "react";
import { Card, CardBody, Col, Table } from "reactstrap";
import { H5, Image, P } from "../../../AbstractElements";
import { TopProductsData } from "../../../Data/Dashboard/Ecommerce/Data";
import { Recentpayments, Date, Name, Product, Status, Total, Paid, Amount, PaidOn } from "../../../Constant";
import HeaderCard from "../../Common/Component/HeaderCard";
import { BestSellersData } from "../../../Data/Dashboard/Ecommerce/Data";
import { Link } from 'react-router-dom';
import { getRecentPayment } from "../../../CommonURL";
import { getDataWithToken } from "../../../Utils/restUtils";
import { toast } from "react-toastify";

const RecentPayment = () => {
    const [paymentData, setPaymentData] = useState([]);

    useEffect(() => {
        recentPayData();
    }, []);

    const recentPayData = async () => {
        const data = await getDataWithToken(getRecentPayment);
        if (data.status) {
            setPaymentData(data.data);
        } else {
            toast.error(data.message)
        }
    };
    return (
        <Fragment>
            <Col xxl="4" xl="4" lg="12" sm="6 box-col-30">
                <Card className="transaction-history" style={{ height: '420px' }}>
                    <HeaderCard title={Recentpayments} setting={true} />
                    <CardBody>
                        <div className="table-responsive sroller-style" style={{ height: '355px', overflowY: 'auto' }}>
                            <Table className="table table-bordernone table-responsive">
                                <thead>
                                    <tr>
                                        <th>{Name}</th>
                                        <th>{PaidOn}</th>
                                        <th>{Amount}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {paymentData.map((item, index) => {
                                        return (<tr key={index}>
                                            <td>
                                                <Link style={{ color: '#534686', marginLeft: '13px' }} to={`${process.env.PUBLIC_URL}/report/payment-collection?user=${item.agent_id}`}>
                                                    <H5 >{item.user_name}</H5>
                                                </Link>
                                            </td >
                                            <td>
                                                <H5>{item.paid_date}</H5>
                                            </td>
                                            <td>
                                                <H5>{item.amount}</H5>
                                            </td>
                                        </tr>)
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                </Card>
            </Col>

        </Fragment>
    );
};

export default RecentPayment;