import React, { Fragment, useState, useEffect } from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { H5, H3, LI, P, UL, Btn } from '../../../../AbstractElements';
import { AboutM, ChangePassword, Update } from '../../../../Constant';
import CommonModalCancel from '../../../UiKits/Modals/common/modalcncl';
import { getDataWithToken } from '../../../../Utils/restUtils';
import { getPersonalInfo } from '../../../../CommonURL';
import EditProfile from './EditProfile';
import ChangePasswordComponent from './ChangePassword';


const AboutMe = () => {
    const [personalDetails, setPersonalDetails] = useState('')
    const [passwordModal, setPasswordModal] = useState(false);
    const [modal, setModal] = useState(false);
    useEffect(() => {
        handlePersonalAddress();
    }, []);

    const AboutMeData = [
        {
            id: 1,
            icon: <i className="icofont icofont-ui-v-card icofont-lg"></i>,
            designation: 'Email',
            place: personalDetails.email
        },
        {
            id: 2,
            icon: <i className="icofont icofont-phone-circle icofont-lg"></i>,
            designation: 'Contact No',
            place: "+" + personalDetails.country_code + " " + personalDetails.mobile
        }
    ];


    const createToggleHandler = () => {
        setModal(!modal);
    };

    const createPasswordToggle = () => {
        setPasswordModal(!passwordModal);
    };

    const handlePersonalAddress = async () => {
        const data = await getDataWithToken(getPersonalInfo);
        data?.data.map((value) => {
            setPersonalDetails(value)
        })
    }

    return (
        <Fragment>
            <CommonModalCancel isOpen={modal}
                title='Edit Profile'
                toggler={() => {
                    setModal(!modal);
                }}
                size="lg">
                <EditProfile
                    personaldata={personalDetails}
                />
            </CommonModalCancel>
            <CommonModalCancel isOpen={passwordModal}
                title='Edit Password'
                toggler={() => {
                    setPasswordModal(!passwordModal);
                }}
                size="lg">
                <ChangePasswordComponent
                    personaldata={personalDetails}
                    modal={setPasswordModal}
                />
            </CommonModalCancel>
            <Card data-intro="This is your Your detail" className="step4">
                <CardHeader>
                    <div>
                        <div style={{ float: 'left' }}>
                            <H3 attrH3={{ className: 'p-0' }} >
                                <Btn attrBtn={{ color: 'transparent btn-link ps-0', onClick: () => { createToggleHandler() } }} >{AboutM}</Btn>
                            </H3>
                        </div>
                        <div style={{ float: 'right' }}>
                            <div>
                                <div className="btn btn-primary" style={{ marginRight: '20px' }} onClick={() => createPasswordToggle()}>{ChangePassword}</div>
                                <div className="btn btn-primary" onClick={() => createToggleHandler()}>{Update}</div>
                            </div>
                        </div>
                        <div style={{ clear: 'both' }}></div>
                    </div>
                </CardHeader>
                <div className={`collapse show`}>
                    <CardBody className="post-about">
                        <UL attrUL={{ className: 'simple-list' }}>
                            <div className="d-flex">
                                <div className="media-body flex-grow-1" style={{ color: '#534686' }}>
                                    <H5 className="d-block">{personalDetails.first_name} {personalDetails.last_name}</H5>
                                </div>
                            </div>
                            <div className="row"> {/* Add row class here */}
                                {AboutMeData.map((item) =>
                                    <div key={item.id} className="col-sm-6 col-md-4 col-xl-12" style={{ marginBottom: '10px', marginTop: '10px' }}> {/* Wrap each LI in a col-md-4 */}
                                        <LI>
                                            <div className="icon">{item.icon}</div>
                                            <div>
                                                <H5>{item.designation}</H5>
                                                <P attrPara={{ className: 'text-dark' }}>{item.place}</P>
                                            </div>
                                        </LI>
                                    </div>
                                )}
                            </div>
                        </UL>
                    </CardBody>
                </div>
            </Card>
        </Fragment>
    );
};

export default AboutMe;
