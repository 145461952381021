import axios from "axios";
import * as authservice from "./authServices";

// Test
//const baseURL = "https://admin.billerq.com/public/api/";

// Live
const baseURL = "https://adminapi.billerq.com/api/";

function getToken() { 
  if (authservice.isLoggedIn()) {
    const userToken = authservice.getUser().userToken;
    return {
      Authorization: "Bearer " + userToken,
    };
  } else {
    return null;
  }
}

function getMasterToken() {
  if (authservice.isLoggedIn()) {
    const masterToken = authservice.getUser().customerToken;
    return {
      Authorization: masterToken,
    };
  } else {
    return null;
  }
}

export async function getMasterDataToken(url) {
  try {
    const response = await axios.get(`${baseURL}${url}`, {
      headers: getMasterToken(),
    });
    // const response = await axios.get(`${masterURL}${url}`);

    if (response.status === 200) {
      return response;
    } else {
      return undefined;
    }
  } catch (error) {
    console.error(error);
    return undefined;
  }
}

export async function postMasterToken(url, data) {
  try {
    const reqHeader = getMasterToken();
    const response = await axios.post(`${baseURL}${url}`, data, {
      headers: reqHeader,
    });
    //console.log("ress", response);
    return response ? response : undefined;
  } catch (error) {
    console.error(error);
  }
}

export async function getData(url) {
  try {
    // const response = await axios.get(`${baseURL}${url}`, {
    //   headers: getToken(),
    // });
    const response = await axios.get(`${baseURL}${url}`);
    //console.log('response>>> ', response.status);
    if (response.status === 200) {
      return response.data;
    } else {
      return undefined;
    }
  } catch (error) {
    console.error(error);
    return undefined;
  }
}

export async function getDataWithToken(url) {
  try {
    const response = await axios.get(`${baseURL}${url}`, {
      headers: getToken(),
    });
    // const response = await axios.get(`${baseURL}${url}`);
    //console.log('response>>> ', response.status);
    if (response.status === 200) {
      return response.data;
    } else {
      return undefined;
    }
  } catch (error) {
    console.error(error);
    return undefined;
  }
}

export async function putData(url, data) {
  try {
    // const response = await axios.put(`${baseURL}${url}`, data, {
    //   headers: getToken(),
    // });
    //console.log(`${baseURL}${url}`);
    const response = await axios.put(`${baseURL}${url}`, data);
    //console.log("ress>>> ", response);
    if (response.status === 200) {
      return response;
    } else {
      return undefined;
    }
  } catch (error) {
    console.error(error);
  }
}

export async function deleteData(url) {
  try {
    const response = await axios.delete(`${baseURL}${url}`, {
      headers: getToken(),
    });
    // console.log("ress", response);
    if (response.status === 204) {
      return response;
    } else {
      return undefined;
    }
  } catch (error) {
    console.error(error);
  }
}

export async function postDataWithToken(url, data) {
  try {
    const reqHeader = getToken();
    const response = await axios.post(`${baseURL}${url}`, data, {
      headers: reqHeader,
    });
    // console.log("ress", response);
    return response ? response : undefined;
  } catch (error) {
    console.error(error);
  }
}

export async function postDataWithoutToken(url, data) {
  try {
    //const reqHeader = getToken();
    const response = await axios.post(`${baseURL}${url}`, data, {
      // headers: reqHeader,
    });
    //console.log("ress", response);
    return response ? response : undefined;
  } catch (error) {
    console.error(error);
    return error
  }
}
