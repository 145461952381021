import React, { useState } from 'react'
import { Col, Form, FormGroup, Label, Row } from 'reactstrap';
import Transparentspinner from '../../Common/loader/Transparentspinner';
import { useForm } from 'react-hook-form';
import { Delete, Save } from '../../../Constant';
import { Btn } from '../../../AbstractElements';
import { toast } from 'react-toastify';
import { deleteComany, deletePackage } from '../../../CommonURL';
import { postDataWithToken } from '../../../Utils/restUtils';


const DeleteCompany = (props) => {
    const customerId = props.id;
    const [loading, setLoading] = useState(false)
    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = async (data) => {
        setLoading(true);
        const postRequest = {
            company_id: customerId,
            confirm: 'confirm_delete_' + data.Customer_id,
        };
        const postDeleteCustomer = await postDataWithToken(deleteComany, postRequest);
        if (postDeleteCustomer.data.status) {
            props.modal(false);
            props.list();
            toast.success(postDeleteCustomer.data.message);
        } else {
            setLoading(false);
            toast.error(postDeleteCustomer.data.message);
        }
        setLoading(false);
    }

    return (
        <>
            {loading && <Transparentspinner />}
            <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                <Row className="pt-3">
                    <Col md="6">
                        <Label className="form-label" for="Customer_id">Enter user id to delete</Label>
                        <input className="form-control" id="Customer_id" type="text" {...register("Customer_id", { required: "This field is required" })} />
                        <p className="text-danger">{errors.Customer_id?.message}</p>
                    </Col>
                </Row>
                <FormGroup></FormGroup>
                <Btn attrBtn={{ color: "primary" }} type="submit">
                    {Save}
                </Btn>
            </Form>
        </>
    )
}

export default DeleteCompany;