import React, { Fragment } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import AboutMe from '../../../Bonus-Ui/Tour/Leftbar/AboutMe';

const UsersProfileContain = () => {

    return (
        <Fragment>
            <Breadcrumbs mainTitle="User Profile" parent="Users" title="User Profile" />
            <Container fluid={true}>
                <div className="user-profile">
                    <Row>
                        {/* <UserProfile details={personalDetails} param={param1}/> */}
                        <Col xl="12" md="12">
                            <div className="default-according style-1 faq-accordion job-accordion">
                                <Row>
                                    <Col xl='12'>
                                        <AboutMe />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col xl="9" md="7" className="xl-65 box-col-70">
                            <Row>
                                {/* <SubscriptionTableTabs params={param1}/> */}
                            </Row>
                        </Col>
                        <Col xl="12" md="12">
                            <Row>
                                {/* <BottomTableTabs params={param1}/> */}
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Container>
        </Fragment>
    );
};
export default UsersProfileContain;