import React from 'react';
import DatePicker from 'react-datepicker';
import { Col } from 'reactstrap';

const SearchFilter = ({ searchValue, onSearchChange }) => {
    return (
        <>
            <Col sm="12" md='5' lg='2'>
                {/* Search Input */}
                <input
                    name="search"
                    className="form-control"
                    placeholder="Search..."
                    type="text"
                    value={searchValue}
                    onChange={(e) => onSearchChange(e.target.value)}
                />
            </Col>
        </>
    )
}

export default SearchFilter