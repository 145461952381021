import React, { Fragment, useEffect, useState } from "react";
import { DollarSign, FileText, ShoppingBag, Users } from "react-feather";
import { Card, CardBody, Col } from "reactstrap";
import { H3, P } from "../../../AbstractElements";
import { IncomeChart, OrderChart, SalesChart, VisitorChart } from "../../../Data/Dashboard/Ecommerce/Chart";
import { getDataWithToken } from "../../../Utils/restUtils";
import { getDashboardCounts } from "../../../CommonURL";
import { toast } from "react-toastify";

const SaleChart = () => {
    const [list, setList] = useState([]);

    useEffect(() => {
        getPackagesData(); 
    }, []);

    const getPackagesData = async () => {;
        const data = await getDataWithToken(getDashboardCounts);
        if(data.status===true){
        setList(data.data);
        }else{
            toast.error(data.message)
        }
    };
    const data = [
         { id: 1, name: 'Total Subscription', icon: <Users />,  value: list?.totalSubscriptions, chart: VisitorChart},
        { id: 1, name: 'Total Sales', icon: <ShoppingBag />, value: list?.totalSale, chart: SalesChart },
        { id: 2, name: 'Total Income', icon: <DollarSign />, value: list?.totalIncome, chart: IncomeChart },
        { id: 3, name: 'Total Dues', icon: <FileText />, value: list?.totalDues, chart: OrderChart },
    ]
    return (
        <Fragment>
            { 
                data.map((item,i)=>
                {   
                    return(
                        <Col lg="3" sm="6" key={i}>
                            <Card className="sale-chart">
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="flex-shrink-0">
                                            <div className="sale-detail">
                                                <div className="icon">{item.icon}</div>
                                                <div className="sale-content">
                                                    <H3>{item.name}</H3>
                                                    <P>{item.value}</P>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="small-chart-view sales-chart" id="sales-chart">
                                            <Chart options={item.chart.options} series={item.chart.series} type="bar" height={100}/>
                                        </div> */}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    )
                })
            }
        </Fragment>
    );
};
export default SaleChart;