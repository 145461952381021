import React, { useState, useEffect, Fragment, useRef } from "react";
import { Col, Card, CardHeader, CardBody, Form, Row } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { H3, Btn, Breadcrumbs } from '../../../AbstractElements';
import { getPaymentList } from '../../../CommonURL';
import { getDataWithToken } from '../../../Utils/restUtils';
import { PaymentColumns } from '../../../Data/MockTable/TempData';
import Transparentspinner from "../../Common/loader/Transparentspinner";
import Progress from "../../Common/Progress";
import { EarningsSvg } from "../../../Data/Widgets/General/Data";
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { useLocation, useNavigate } from "react-router";
import { Filter, PaymentMethod, Search, Status } from "../../../Constant";
import { useForm } from "react-hook-form";
import { paymentStatusOptions } from "../../../Constant/DropdownValue";

const PaymentTable = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isStatus = useRef(null);
    const isPayStatus = useRef(null);
    const searchParams = location.search;
    const params = new URLSearchParams(searchParams);
    const sortedBy = params.get("sortBy") || '';
    const sortedOrder = params.get("sortOrder") || '';
    const [filters, setFilters] = useState({
        search: params.get('search') || '',
        status: params.get('status') || '',
        payStatus: params.get('payStatus') || '',
        start_date: params.get('start_date') ? moment(params.get('start_date'), "DD-MM-YYYY").toDate() : '',
    });
    const [startDate, setstartDate] = useState(null);
    const [tempList, setTempList] = useState([]);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [StaticWidgetsData, setStaticWidgetsData] = useState([]);
    const [sortConfig, setSortConfig] = useState({ column: sortedBy, order: sortedOrder });
    const [currentPage, setCurrentPage] = useState(1);
    const formatStartDate = filters.start_date ? moment(filters.start_date).format("DD-MM-YYYY") : '';
    const { register, setValue, handleSubmit, } = useForm({
        defaultValues: {
            search: filters.search,
        },
    });

    const statusOptions = [
        { value: "online", label: "Online" },
        { value: "offline", label: "Offline" }
    ]

    useEffect(() => {
        fetchPayment(1);
    }, []);

    useEffect(() => {
        setValue('search', filters.search);
    }, [setValue, filters.search]);

    const fetchPayment = async (page, size = perPage) => {
        setLoading(true);
        const response = await getDataWithToken(getPaymentList + `?page=${page}&page_length=${size}&search=${filters.search}
            &payment_date=${formatStartDate}&status=${filters.status}&payment_method=${filters.payStatus}
            &sort_by=${sortConfig.column ? sortConfig.column : ''}&sort_order=${sortConfig.order ? sortConfig.order : ''}`);
        setResetPaginationToggle(!resetPaginationToggle);
        let serialNo = (page - 1) * size + 1;
        setTempList(response.data.payment.data.map((e, index) => {
            return {
                Si: serialNo + index,
                Name: <span>{e.user_name}</span>,
                Invoice_no: e.invoice_number,
                Txn_id: e.transaction_id,
                Industry: e.industry_name,
                Payment_method: e.payment_method_name,
                Date: e.payment_date,
                Amount: e.paid_amount,
                Status: e.payment_status,
            }
        }))
        setStaticWidgetsData([
            {
                id: 1,
                title: 'Total',
                counter: parseFloat(response.data.total.replace(/,/g, "")),
                svg: <EarningsSvg />,
                class: 'progress-gradient-secondary',
                progress: '75%'
            }
        ]);
        setTotalRows(response.data.payment.total);
        setLoading(false);
    };

    const onSubmit = async (data) => {
        // e.preventDefault();
        const search = data.search || '';
        const start_date = startDate ? moment(startDate).format("DD-MM-YYYY") : '';
        const status = isStatus.current.value;
        const payStatus= isPayStatus.current.value;
        const queryParams = new URLSearchParams({
            search: search,
            start_date,
            status: status,
            payStatus: payStatus,
            sortBy: sortConfig.column || '',
            sortOrder: sortConfig.order || '',
        }).toString();
        navigate(`/billing/payment-list?${queryParams}`);
        setFilters({
            search,
            start_date,
            status,
            payStatus
        });
        fetchPayment(currentPage, perPage);
    };

    const handleChange = date => {
        setstartDate(date);
    };


    const handlePageChange = page => {
        fetchPayment(page);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        fetchPayment(page, newPerPage);
        setPerPage(newPerPage);
    };

    return (
        <Fragment>
            {loading && <Transparentspinner />}
            <Breadcrumbs mainTitle='Payment' parent="Companies" title="Payment" />
            <Progress details={StaticWidgetsData} />
            <Card>
                <CardHeader>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <H3>Payment List</H3>
                    </div>
                </CardHeader>
                <CardBody>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Row className="ps-3 gap-sm-3 gap-md-2 mb-4 filterGap">
                            <Col sm="12" md='5' lg='2'>
                                <input name='search' className="form-control" placeholder={Search} id="validationCustom01" type="text"  {...register('search')}
                                />
                            </Col>
                            <Col sm="12" md="5" lg='2' className="form-element" >
                                <div style={{ position: 'relative', zIndex: 2 }}>
                                    <DatePicker 
                                    className="form-control digits" selected={startDate} onChange={handleChange} placeholderText="Enter date"
                                    />
                                </div>
                            </Col>
                            <Col sm="12" md="5" lg='2' className="form-element">
                                <select className="form-select" ref={isStatus}>
                                    <option value="">{Status}</option>
                                    {paymentStatusOptions.map((option, index) => (
                                        <option key={index} value={option.value}>{option.label}</option>
                                    ))}
                                </select>
                            </Col>
                            <Col sm="12" md="5" lg='2' className="form-element">
                                <select className="form-select" ref={isPayStatus}>
                                    <option value="">{PaymentMethod}</option>
                                    {statusOptions.map((option, index) => (
                                        <option key={index} value={option.value}>{option.label}</option>
                                    ))}
                                </select>
                            </Col>
                            <Col sm="12" md="5" lg='1' className="custom-filtrs">
                                <Btn attrBtn={{ color: "primary" }} type="submit">{Filter}</Btn>
                            </Col>
                        </Row>
                    </Form>
                    <div className="order-history table-responsive">
                        <DataTable
                            columns={PaymentColumns}
                            data={tempList}
                            pagination
                            paginationServer
                            defaultSortAsc
                            paginationTotalRows={totalRows}
                            paginationDefaultPage={currentPage}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                        />
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    );


};

export default PaymentTable;